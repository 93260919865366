import React from "react";
import HealthPackages from "./Health";
import CustomerReviews from "./Customers";
import BookTest from "./BookTest";
import WhyChooseUs from "./WhyChooseUs";
import TopOffers from "./TopOffers";
import CheckupSection from "./Checkup";
import HomeTestingSteps from "./Testing";
import StatsSection from "./StatsSection";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import NewsLetter from "./NewsLetter";
import Footer from "../layout/Footer";
import OurDirectors from "./OurDirectors";
import SearchSuggestion from "./SearchSuggestion";
import { Link } from "react-router-dom";
import { LuHeartPulse } from "react-icons/lu";

const TestPage = () => {
  return (
    <>
      <Navbar />
      <Navbar2 />
      <div
        className="flex items-start bg-cover bg-center "
        style={{
          backgroundImage: 'url("images/Rectangle 5.png")',
          width: "100%",
          height: "461px",
        }}
      >
        <div className="max-w-screen-2xl px-4 sm:px-6 lg:px-20">
          {/* Header Text */}
          <div className="mt-8 sm:mt-12 text-center sm:text-left">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white font-museo leading-tight sm:leading-10">
              Accurate Tests, <span className="font6">Trusted Care</span>
            </h1>
            <p className="text-base sm:text-lg mt-4 sm:mt-8 text-white font-museo font-medium opacity-80">
              Want to take a test?
            </p>
          </div>

          <div className="relative mt-5 sm:mt-7">
            <SearchSuggestion />
          </div>

          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6 mt-6 sm:mt-9">
            <Link to="/prescription">
              <button className="flex items-center justify-center sm:justify-start space-x-4 bg-white border border-gray-300 border-dashed px-8 py-5 sm:px-14 sm:py-7 rounded-lg shadow-md font-museo font1 hover:bg-blue-600 hover:text-white transition">
                <img
                  src="images/upload.png"
                  alt="Upload"
                  width="28px"
                  height="28px"
                />
                <span className="font-semibold pt-1 text-sm sm:text-lg">
                  Upload Your Prescription
                </span>
              </button>
            </Link>
            <Link to={"/testBrowse"}>
              <button className="flex items-center justify-center sm:justify-start space-x-4 bg-white hover:bg-red-600 hover:text-white border border-gray-300 border-dashed px-8 py-5 sm:px-20 sm:py-7 rounded-lg shadow-md font6 hover-color1 transition font-museo">
                <LuHeartPulse className="w-8 h-8" />
                <span className="font-semibold pt-1 text-base sm:text-lg">
                  Get a Package
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <CheckupSection />
      <HealthPackages />
      <HomeTestingSteps />
      <TopOffers />
      <OurDirectors />
      <StatsSection />
      <WhyChooseUs />
      <BookTest />
      <CustomerReviews />
      <NewsLetter />
      <Footer />
    </>
  );
};

export default TestPage;
